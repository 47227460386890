<template>
  <div class="container-main-content home">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">
          Chọn ứng dụng bạn muốn sử dụng
        </h4>
        <div class="content-inner__body-list mb-4">
          <div
            class="content-inner__body-app mb-4"
            @click="handleSelectNextshop"
          >
            <img src="../assets/images/nextshop.png" alt="" />
            <div class="px-2">
              <h6 class="f14 fw600">NextShop</h6>
              <span class="f14 text-second-color">
                Giải pháp quản lý kinh doanh toàn diện
              </span>
            </div>
            <img src="../assets/images/arrow-right-square.svg" alt="" />
          </div>
          <div class="content-inner__body-app--disabled mb-4">
            <img src="../assets/images/nextcam.png" alt="" />
            <div class="px-4">
              <h6 class="f14 fw600">NextCam - Sắp ra mắt</h6>
              <span class="f14 text-second-color">
                Giải pháp an ninh thông minh dành cho bạn
              </span>
            </div>
            <img src="../assets/images/arrow-right-square.svg" alt="" />
          </div>
        </div>
      </div>
      <p class="f14 text-second-color mb-4">
        <a href="#" class="link-primary-color" @click.prevent="handleLogout">
          Đăng nhập tài khoản khác
        </a>
      </p>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { REDIRECT_URI, NEXT360_APPID } from "../.env";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      aliasMerchant: this.$route.params.alias,
      appId: this.$route.query.appId || NEXT360_APPID,
      redirectUri: this.$route.query.redirectUri || REDIRECT_URI,
    };
  },
  created() {
    if (this.$route.query.appId && this.$route.query.redirectUri) {
      this.$router.push(
        `/authorization/${this.aliasMerchant}?appId=${this.appId}&redirectUri=${encodeURIComponent(this.redirectUri)}`
      );
    }
  },
  methods: {
    handleSelectNextshop() {
      window.location.href = `/authorization/${this.aliasMerchant}?appId=${this.appId}&redirectUri=${encodeURIComponent(this.redirectUri)}`;
    },
    handleLogout() {
      this.$router.push("/logout");
    },
  },
};
</script>
